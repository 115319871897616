* {
  scrollbar-width: thin;
}

/* ====== Calendar CSS ======= */

html[data-theme='dark'] {
  --bg-color: #0d0d0d;
  --borderColor: #434343;
  --headerColor: #242424;
  --timeTextColor: #999;
  --headerTextColor: #fff;
  --dateTextColor: #fff;
  --scrollbarThumb: white;
  --tagsInputText: #58585a;
  --tagsBg: #0088cc;
  --clockBg: #343434;
}

html {
  --bg-color: #ffffff;
  --borderColor: #f2f4f6;
  --headerColor: #f2f6ff;
  --timeTextColor: #98a9bc;
  --headerTextColor: #58585a;
  --dateTextColor: #000000;
  --scrollbarThumb: #d9e4fb;
  --tagsInputText: #f8faff;
  --tagsBg: #2d3d95;
  --clockBg: #ffffff;
}

.rbc-month-view,
.rbc-time-view {
  border: 1px solid var(--borderColor) !important;
  border-radius: 4px;
}

.rbc-month-header,
.rbc-time-header {
  height: 50px;
  align-items: center;
  background-color: var(--headerColor);
}

/* .rbc-header {
  pointer-events: none;
} */

.rbc-header,
.rbc-time-header-gutter {
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-bottom: 1px solid var(--borderColor) !important;
  background: transparent !important;
}

.rbc-time-header-content {
  border-bottom: none;
}

.rbc-time-header-cell {
  border-bottom: 1px solid var(--borderColor);
}

.rbc-time-view-resources .rbc-time-gutter {
  background-color: var(--bg-color) !important;
  border-right: 2px solid var(--borderColor) !important;
  z-index: 4 !important;
}

.rbc-time-header-content > .rbc-time-header-cell > .rbc-header {
  border-bottom: none !important;
}

.rbc-header + .rbc-header {
  border-left: none !important;
  height: 50px !important;
}

.rbc-month-row {
  overflow: visible;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid var(--borderColor) !important;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-time-content > * + * > * {
  border-left: 1px solid var(--borderColor) !important;
}

.rbc-time-header.rbc-overflowing {
  border: none !important;
}

.rbc-allday-cell,
.rbc-event-label {
  display: none !important;
}

.rbc-time-content {
  border-top: none !important;
}

.rbc-time-header-content,
.rbc-time-view .rbc-row {
  height: 100% !important;
  border-left: none !important;
}

.rbc-timeslot-group {
  border-bottom: 2px solid var(--borderColor) !important;
}

.rbc-time-slot {
  border-top: none !important;
  /* min-height: 35px !important; */
  min-height: 13.25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: var(--timeTextColor) !important;
  font-size: 9px !important;
  font-weight: 400 !important;
}

.rbc-time-slot + .rbc-time-slot {
  border-top: 1px solid var(--borderColor) !important;
}

.rbc-date-cell {
  padding-top: 12px;
  padding-right: 15px;
  padding-bottom: 12px;
  /* pointer-events: none; */
}

.rbc-header span {
  font-size: 14px;
  font-weight: 600;
  color: var(--headerTextColor);
}

.rbc-button-link {
  color: var(--dateTextColor);
}

.rbc-off-range > .rbc-button-link {
  color: var(--timeTextColor);
}

/* .rbc-day-slot .rbc-event-content {
  width: 60%
} */

.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 0;
  background: none;
  border-radius: 0;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-day-slot .rbc-event {
  border: none !important;
}

.rbc-event,
.rbc-event-content {
  height: 100%;
}

.rbc-event.rbc-selected {
  background: none;
}

.rbc-row-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* .rbc-row + .rbc-row {
  height: 100% !important;
} */

.rbc-current .rbc-button-link {
  color: #4d7cfe !important;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: none !important;
}

.rbc-time-view-resources .rbc-time-header-gutter {
  /* border-right: none !important; */
  border: none !important;
}

.rbc-time-column {
  border-right: 1px solid var(--borderColor);
}

/* .rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: auto;
} */

/* === hide current time indicator === */
.rbc-current-time-indicator {
  /* display: none; */
  background-color: red;
  /* #2D3D95 */
  height: 1.5px;
}

/* ==== Alert box === */
.alert-style {
  color: white;
  padding: 10px;
  font: inherit;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}

.alert-error {
  background-color: #ed4242;
}

.alert-success {
  background-color: #34a885;
}

.alert-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.alert-close-icon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
}

/* ==== TagsInput === */
.react-tagsinput {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.react-tagsinput--focused {
  border-color: var(--tagsBg) !important;
}

.react-tagsinput-input {
  width: auto;
  color: var(--headerTextColor);
}

.react-tagsinput-tag {
  background-color: var(--tagsBg);
  border: 1px solid var(--tagsBg);
  color: white;
}

::placeholder {
  color: var(--headerTextColor) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--headerTextColor) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--headerTextColor) !important;
}

/* ==== react-quill === */

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-snow.ql-toolbar button {
  background: #d9e4fb;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-editor {
  color: var(--headerTextColor);
}

.ql-editor.ql-blank::before {
  color: var(--headerTextColor);
}

.ql-tooltip {
  left: 8px !important;
  z-index: 1;
}

@media only screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    max-width: 5px;
    max-height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarThumb);
    border-radius: 50px;
    background-clip: padding-box;
  }
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
  border-radius: 50px;
  background-clip: padding-box;
} */

.ant-picker-focused {
  box-shadow: none !important;
  border-color: var(--tagsBg) !important;
}

.ant-picker-input > input {
  color: var(--headerTextColor) !important;
}

.ant-picker .ant-picker-clear {
  background-color: var(--clockBg);
  right: 0;
}

/* SWIPER CSS */

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px;
}

@media only screen and (max-height: 575.98px ) and (max-width: 668px ) and (orientation: landscape) {
  .noteDialog-style{
   height: 100% !important;
   width: 100% !important;
  }
}

.lazy-load-image-background {
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
