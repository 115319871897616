body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* input[type='checkbox'] {
  width: 15px;
  height: 15px;
  border: 1px solid #e5e5e5;
  accent-color: #2d3d95;
  cursor: pointer;
  border-radius: 4px;
}

input[type='checkbox']:checked {
  border: 1px solid #e5e5e5;
  accent-color: #2d3d95;
}

input[type='checkbox']:checked + label {
  color: #58585a;
  font-weight: 400;
  font-size: 12px;
  margin-left: 5px;
}

input[type='checkbox'] + label {
  color: #58585a;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
} */

 /* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 /* Firefox */
 input[type=number] {
   -moz-appearance: textfield;
 }